import { graphql, useStaticQuery } from 'gatsby';
import { use } from 'i18next';
import { useMemo } from 'react';

export type MainMenuCategoryTree = {
  component: 'menu.category-tree';
  categoryId: number;
};

export type MainMenuItem = MainMenuCategoryTree;

export default function useMainMenu(locale: string) {
  const { allStrapiMainMenu } = useStaticQuery(graphql`
    query {
      allStrapiMainMenu {
        nodes {
          locale
          items {
            ... on STRAPI__COMPONENT_MENU_CATEGORY_TREE {
              strapi_component
              category {
                strapi_id
              }
            }
            ... on STRAPI__COMPONENT_MENU_EXTERNAL_LINK {
              strapi_component
              title
              link
            }
          }
        }
      }
    }
  `);

  return useMemo(() => {
    if (!locale) return [];

    const menu = allStrapiMainMenu.nodes.find(
      (node: any) => node.locale.toLowerCase() === locale?.toLowerCase()
    );

    if (!menu) {
      return [];
    }

    return menu.items;
  }, [allStrapiMainMenu, locale]);
}
