import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export type MobileMenuTriggerProps = {
    className?: string;
    open?: boolean;
    onClick?: () => void;
};

export default function MobileMenuTrigger({
    className = '',
    open = false,
    onClick
}: MobileMenuTriggerProps) {
    return (
        <div onClick={onClick} className={`flex items-center justify-center w-14 cursor-pointer hover:bg-slate-800 ${className}`}>
            <FontAwesomeIcon icon={open ? faTimes : faBars} className="text-3xl" />
        </div>
    );
}