import { useCallback, useEffect, useRef, useState } from "react";
import { Darkness } from "./useDarkness";

export default function usePreferredColorSchema(): Darkness {
    const [colorSchema, setColorSchema] = useState<Darkness>('light');

    const handlePreferredColorScheme = useCallback((e: MediaQueryListEvent) => {
        if(e.matches) {
            setColorSchema('dark');
        } else {
            setColorSchema('light');
        }
    }, []);

    useEffect(() => {
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", handlePreferredColorScheme);

        handlePreferredColorScheme({
            matches: window.matchMedia("(prefers-color-scheme: dark)").matches
        } as any);

        return () => {
            window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", handlePreferredColorScheme);
        }
    }, []);

    return colorSchema;
}