import React from 'react';
import makePath from '../../../helpers/makePath';
import useCategoryTree from '../../../hooks/useCategoryTree';
import { useLocale } from '../../../hooks/useLocale';
import MainMenuItem from '../MainMenuItem';

export type MainMenuCategoryItemProps = {
  categoryId: number;
  active?: boolean;
  onActivate?: (id: number) => void;
};

export default function MainMenuCategoryItem({
  categoryId,
  active,
  onActivate,
}: MainMenuCategoryItemProps) {
  const locale = useLocale();
  const categories = useCategoryTree(locale, categoryId);

  const rootCategory = categories.find((cat) => cat.strapi_id === categoryId);
  if (!rootCategory) return null;
  const hasChildren =
    rootCategory.children.length + rootCategory.pages.length > 0;

  if (hasChildren) {
    return (
      <MainMenuItem
        to={makePath(rootCategory.locale, rootCategory.slug)}
        active={active}
        onFocus={() => onActivate(categoryId)}
        onMouseEnter={() => onActivate(categoryId)}
      >
        {rootCategory.title}
      </MainMenuItem>
    );
  } else {
    return (
      <MainMenuItem
        onMouseEnter={() => onActivate(null)}
        to={makePath(rootCategory.locale, rootCategory.slug)}
      >
        {rootCategory.title}
      </MainMenuItem>
    );
  }
}
