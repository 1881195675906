import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type TranslatedProps = {
  k: string;
  ns?: string;
  children?: ReactNode;
};

export function Translated({
  children,
  ns = 'translation',
  k,
}: TranslatedProps) {
  const { t, ready } = useTranslation(ns, { useSuspense: false });

  if (!ready) {
    return <>{children}</>;
  }

  return <>{t(k)}</>;
}

export default Translated;
