import { PageProps } from 'gatsby';
import React, { ReactNode, Suspense, useCallback, useEffect } from 'react';
import { LocaleProvider } from '../../hooks/useLocale';
import Header from '../header/Header';
import { MDXProvider } from '@mdx-js/react';
import Paragraph from '../typography/Paragraph';
import usePreferredColorSchema from '../../hooks/usePreferredColorSchema';
import UnorderedList from '../typography/UnorderedList';
import ListItem from '../typography/ListItem';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import Footer from '../footer/Footer';
import CookieNotice from '../gdpr/Notice';

export default function Layout({ children, ...props }: PageProps<any, any>) {
  const mdxComponents = {
    p: Paragraph,
    ul: UnorderedList,
    li: ListItem,
  };

  const colorSchema = usePreferredColorSchema();

  const { i18n } = useTranslation();
  const locale = props?.params?.locale;

  useEffect(() => {
    if (locale && locale != i18n.language) {
      i18n.changeLanguage(locale);
    }
  }, [locale]);

  useEffect(() => {
    if (
      colorSchema === 'dark' &&
      !document.documentElement.classList.contains('dark')
    ) {
      document.documentElement.classList.add('dark');
    } else if (
      colorSchema !== 'dark' &&
      document.documentElement.classList.contains('dark')
    ) {
      document.documentElement.classList.remove('dark');
    }
  }, [colorSchema]);

  return (
    <LocaleProvider locale={locale}>
      <MDXProvider components={mdxComponents}>
        <div className="antialiased flex flex-col min-h-screen w-100">
          <Header {...props} />
          <div className="pt-14 flex flex-col flex-grow">{children}</div>
          {locale && <Footer />}
        </div>
        <CookieNotice />
      </MDXProvider>
    </LocaleProvider>
  );
}
