import { delay } from 'lodash';
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import useDelayed from '../../hooks/useDelayed';
import useMeasure from '../../hooks/useMeasure';
import useMultiRef from '../../hooks/useMultiRef';
import Overlay from './Overlay';

export type DrawerProps = {
  open?: boolean;
  keepHeader?: boolean;
  children?: ReactNode;
  className?: string;
};

const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  (
    { open = false, keepHeader = false, children, className = '' }: DrawerProps,
    forwardedRef
  ) => {
    const [measureRef, measurements] = useMeasure<HTMLDivElement>();
    const delayedOpen = useDelayed(open, 500);

    const ref = useMultiRef([measureRef, forwardedRef]);

    return (
      <Overlay visible={open} keepHeader={keepHeader}>
        <div
          ref={ref}
          style={{
            transform: `translateX(${open ? 0 : measurements.size.width}px)`,
          }}
          className={`absolute inset-y-0 right-0 max-w-full overflow-hidden shadow-2xl transition-transform duration-500 ${
            open || delayedOpen ? '' : 'invisible'
          } ${className}`}
        >
          {children}
        </div>
      </Overlay>
    );
  }
);

export default Drawer;
