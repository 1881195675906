import React, { HTMLAttributes, ReactNode } from "react";

export type ParagraphProps = {
    children: ReactNode
} & HTMLAttributes<HTMLParagraphElement>;

export default function Paragraph({children, className = '', ...props}: ParagraphProps) {
    return (
        <p className={`mb-4 last:mb-0 leading-loose ${className}`} {...props}>{children}</p>
    );
}