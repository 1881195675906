import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { SocialMediaIcon } from '../components/footer/SocialMediaLink';
import { StrapiApiImage } from '../components/images/StrapiImage';

export type FooterSocialLinkData = {
  strapi_id: number;
  link: string;
  icon: SocialMediaIcon;
};

export type FooterLinkData = {
  strapi_id: number;
  title: string;
  to: string;
};

export type FooterItemData = {
  strapi_id: number;
  title: string;
  content?: any;
  links?: FooterLinkData[];
};

export type FooterPartnerData = {
  title: string;
  link?: string;
  logo: StrapiApiImage;
};

export type FooterData = {
  locale: string;
  slogan: string;
  copyright: string;
  contactCta?: string;
  contactPhone: string;
  contactEmail: string;
  items?: FooterItemData[];
  socialLinks?: FooterSocialLinkData[];
  partnerLogos?: FooterPartnerData[];
};

export default function useFooter(locale: string): FooterData {
  const { allStrapiFooter } = useStaticQuery(graphql`
    query {
      allStrapiFooter {
        nodes {
          locale
          slogan
          copyright
          contactEmail
          contactPhone
          contactCta
          items {
            strapi_id
            title
            content {
              data {
                childMdx {
                  body
                }
              }
            }
            links {
              title
              to
              strapi_id
            }
          }
          socialLinks {
            strapi_id
            link
            icon
          }
          partnerLogos {
            logo {
              ...StrapiImageConstrained
            }
            title
            link
          }
        }
      }
    }
  `);

  return useMemo(() => {
    const fallbackFooter = allStrapiFooter.nodes.find(
      (node: any) => node.locale.toLowerCase() === 'en'
    );
    const footer = allStrapiFooter.nodes.find(
      (node: any) =>
        node.locale.toLowerCase() === (locale?.toLowerCase() ?? 'en')
    );

    if (!footer) {
      return fallbackFooter as FooterData;
    }

    return footer as FooterData;
  }, [allStrapiFooter, locale]);
}
