import React, { HTMLProps, MouseEvent, MouseEventHandler, PropsWithoutRef, ReactNode, useCallback } from "react";
import Link, { LinkProps } from "../link/Link";

export type MainMenuItemProps = {
    active?: boolean;
    children: ReactNode;
    to?: string;
} & Omit<HTMLProps<HTMLAnchorElement>, 'href'|'ref'>;

export function MainMenuItem({
    active = false,
    children,
    to,
    onClick,
    ...rest
}: MainMenuItemProps) {
    const className = `font-bold px-6 py-1 rounded-md ${active ? 'bg-slate-900' : '' } hover:bg-slate-900 font-medium transition-colors`;

    const handleLinkClick = useCallback((evt: MouseEvent<HTMLAnchorElement>) => {
        evt.preventDefault();
        onClick && onClick(evt);
    }, [onClick]);

    if(to) {
        return (
            <Link to={to} className={className} {...rest}>{children}</Link>
        );
    }

    return (
        <a onClick={handleLinkClick} className={className} {...rest}>{children}</a>
    );
}

export default MainMenuItem;