import React, { ReactNode } from 'react';

export type ListItemProps = {
  children: ReactNode;
};

export function ListItem({ children }: ListItemProps) {
  return <li className="mb-2">{children}</li>;
}

export default ListItem;
