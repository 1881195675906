import React from 'react';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import Layout from './src/components/layout/Layout';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import './src/styles/global.css';
import { I18nextProvider, initReactI18next } from 'react-i18next';

/**
 * Trigger analytics on route change
 */
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (process.env.NODE_ENV !== `production` || typeof gtag !== `function`) {
    return null;
  }

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;
    window.gtag(`event`, `page_view`, { page_path: pagePath });
  };

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }

  return null;
};

/**
 * Wrap page with layout component
 */
export const wrapPageElement = ({ element, props }) => {
  const locale = props?.pageContext?.locale;

  if (!i18n.isInitialized) {
    i18n
      .use(initReactI18next)
      .use(Backend)
      .init({
        lng: locale,
        fallbackLng: 'en',
        initImmediate: false,
        debug: process.env.NODE_ENV === 'development',
        react: {
          useSuspense: false,
        },
      });
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Layout {...props}>{element}</Layout>
    </I18nextProvider>
  );
};

/**
 * Measure scrollbar width
 */
var scrollDiv = document.createElement('div');
scrollDiv.setAttribute(
  'style',
  'overflow-y:scroll; width: 100px; height: 100px;'
);
document.body.appendChild(scrollDiv);
window.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
document.body.removeChild(scrollDiv);
