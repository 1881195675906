import React from "react";
import Link, { LinkProps } from "../link/Link";
import CircleFlag from "./CircleFlag";

export type LanguageSwitcherItemProps = {
  locale: string;
  slug: string;
};

export default function LanguageSwitcherItem({
    locale,
    slug,
    ...props
}: LanguageSwitcherItemProps) {
    const path = `/${locale.toLowerCase()}/${slug.replace(/^\//, "")}`;

    return (
        <Link
          className={`w-14 h-14 flex items-center justify-center hover:bg-slate-200 dark:hover:bg-slate-700`}
          to={path}
          {...props}
        >
          <CircleFlag countryCode={locale} className="w-7 h-7" />
        </Link>
      );
}