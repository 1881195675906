import { graphql, useStaticQuery } from 'gatsby';
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import StrapiImage, { StrapiImageProps } from './StrapiImage';

export type LogoProps = {
  colour?: 'positive' | 'negative';
  format?: 'logo' | 'icon';
} & Omit<StrapiImageProps, 'image' | 'alt' | 'color' | 'fitWidth'>;

export default function Logo({
  colour = 'positive',
  format = 'logo',
  ...props
}: LogoProps) {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      strapiLogo {
        logoPositive {
          ...StrapiImageConstrained
        }
        logoNegative {
          ...StrapiImageConstrained
        }
        iconPositive {
          ...StrapiImageConstrained
        }
        iconNegative {
          ...StrapiImageConstrained
        }
      }
    }
  `);

  return (
    <StrapiImage
      fitWidth={true}
      image={data.strapiLogo[`${format}${upperFirst(colour)}`]}
      {...props}
    />
  );
}
