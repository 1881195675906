import React, { HTMLProps, ReactNode } from "react";

export type PopoverBodyProps = {
    children: ReactNode
} & HTMLProps<HTMLDivElement>;

export default function PopoverBody({children, className, ...props}: PopoverBodyProps) {
    return (
        <div className={`shadow-md ${className}`} {...props}>
            {children}
        </div>
    );
}