import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useMeasure from '../../hooks/useMeasure';
import { useSpring, animated, SpringRef } from 'react-spring';
import { usePrevious } from '../../hooks/usePrevious';

export type MainMenuDropdownProps = {
  children: ReactNode;
  active?: boolean;
  animationRef?: SpringRef<any>;
};

export function MainMenuDropdown({
  children,
  active = false,
  animationRef,
}: MainMenuDropdownProps) {
  const [measureRef, measurements] = useMeasure<HTMLDivElement>();
  const [windowHeight, setWindowHeight] = useState(0);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  const layout = useCallback(() => {
    setWindowHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', layout);
    setScrollbarWidth((window as any).scrollbarWidth);

    return () => {
      window.removeEventListener('resize', layout);
    };
  }, []);

  const height = measurements.size.height;

  const [styles, ref] = useSpring(
    {
      from: {
        height: 0,
        overflowY: 'hidden',
      },
      to: active
        ? [
            {
              height: height,
              overflowY: 'hidden',
            },
            {
              overflowY: height > windowHeight * 0.7 ? 'scroll' : 'hidden',
            },
          ]
        : [{ overflowY: 'hidden' }, { height: 0 }],
      ref: animationRef,
    },
    [active, height, windowHeight]
  );

  return (
    <animated.div
      style={{
        ...styles,
        paddingRight: styles.overflowY.to((val) =>
          val === 'scroll' ? 0 : scrollbarWidth
        ),
      }}
      className="fixed inset-x-0 top-14 max-h-[80vh] z-40 bg-slate-100 dark:bg-slate-900 overflow-hidden"
    >
      <div ref={measureRef} className="container m-auto">
        <div className="sm:px-8 px-4">{children}</div>
      </div>
    </animated.div>
  );
}

export default MainMenuDropdown;
