import { useCallback, useEffect, useState } from 'react';

export function useLocalStorage(
  key: string,
  defaultValue: string | null = null
): [string | null, (val: string | null) => void] {
  const [internalVal, setInternalVal] = useState<string | null>(defaultValue);

  const handleStorageChange = useCallback(
    (evt: StorageEvent) => {
      if (evt.key !== key) return;
      if (evt.newValue !== internalVal) {
        setInternalVal(evt.newValue);
      }
    },
    [setInternalVal, internalVal, key]
  );

  useEffect(() => {
    setInternalVal(window.localStorage.getItem(key) ?? defaultValue);
  }, [defaultValue, setInternalVal]);

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [handleStorageChange]);

  const setVal = useCallback(
    (val: string | null) => {
      if (val === null) {
        setInternalVal(null);
        window.localStorage.removeItem(key);
      } else {
        setInternalVal(val);
        window.localStorage.setItem(key, val);
      }
    },
    [key]
  );

  return [internalVal, setVal];
}
