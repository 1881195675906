import React from 'react';
import { StrapiApiImage } from '../images/StrapiImage';
import Helmet from 'react-helmet';
import { LanguageSwitcherItemProps } from '../language-switcher/LanguageSwitcherItem';
import { getBaseUrl, makeAbsolute } from '../../helpers/makePath';
import { graphql } from 'gatsby';

export type SiteMetaProps = {
  title: string;
  description: string;
  socialTitle?: string;
  socialDescription?: string;
  socialImage?: StrapiApiImage;
  locale?: string;
  slug?: string;
  availableLocales?: LanguageSwitcherItemProps[];
};

export default function SiteMeta({
  title,
  description,
  socialTitle,
  socialDescription,
  socialImage,
  locale = 'en',
  slug = '/',
  availableLocales = [],
}: SiteMetaProps) {
  //TODO: Add locales without region (https://developers.google.com/search/docs/advanced/crawling/localized-versions?hl=de)

  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      <title>{title}</title>
      <meta name="description" content={description} />

      <link
        rel="alternate"
        href={makeAbsolute(locale, slug)}
        hrefLang={locale}
      />
      {availableLocales.map((locale) => (
        <link
          key={`${locale.locale}${locale.slug}`}
          rel="alternate"
          href={makeAbsolute(locale.locale, locale.slug)}
          hrefLang={locale.locale}
        />
      ))}
      <link rel="alternate" href={getBaseUrl()} hrefLang="x-default" />

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Signature Products GmbH" />
      <meta property="og:title" content={socialTitle ?? title} />
      <meta
        property="og:description"
        content={socialDescription ?? description}
      />
      {socialImage && (
        <meta
          property="og:image"
          content={`${getBaseUrl()}${socialImage.localFile.publicURL}`}
        />
      )}
      <meta property="og:url" content={makeAbsolute(locale, slug)} />
      <meta property="og:locale" content={locale.replace('-', '_')} />
      {availableLocales.map((locale) => (
        <meta
          key={locale.locale}
          property="og:locale:alternate"
          content={locale.locale.replace('-', '_')}
        />
      ))}

      <meta name="twitter:title" content={socialTitle ?? title} />
      <meta
        name="twitter:description"
        content={socialDescription ?? description}
      />
      {socialImage && (
        <meta
          name="twitter:image"
          content={`${getBaseUrl()}${socialImage.localFile.publicURL}`}
        />
      )}
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export const query = graphql`
  fragment SiteMeta on STRAPI__COMPONENT_PAGE_SITE_META {
    title
    description
    socialTitle
    socialDescription
    socialImage {
      localFile {
        publicURL
      }
    }
  }
`;
