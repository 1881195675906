import { MutableRefObject, useCallback, useRef, useState } from "react";
import { useEffect } from "react";

export type HoverOptions<T extends HTMLElement> = {
    ref: MutableRefObject<T>;
    interval?: number;
    sensitivity?: number;
    timeout?: number;
    onStartHover?: () => void;
    onEndHover?: () => void;
};

export default function useHover<T extends HTMLElement>({
    ref,
    interval = 100,
    sensitivity = 10,
    timeout = 0,
    onStartHover,
    onEndHover
}: HoverOptions<T>) {
    const [isHovering, setIsHovering] = useState(false);
    const timer = useRef(null);
    const mouseLocation = useRef({x:0, y:0, pX: 0, pY: 0});

    const updateHovering = useCallback((value: boolean) => {
        if(!isHovering && value && onStartHover) {
            onStartHover();
        }

        if(isHovering && !value && onEndHover) {
            onEndHover();
        }

        setIsHovering(value);
    }, [isHovering, onStartHover, onEndHover]);

    const resetTimer = useCallback(() => {
        if(timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
    }, [])

    const handleDelayedMouseOut = useCallback(() => {
        resetTimer();
        updateHovering(false);
    }, [updateHovering])

    const handleTick = useCallback(() => {
        resetTimer();

        if (Math.abs(mouseLocation.current.pX - mouseLocation.current.x) + Math.abs(mouseLocation.current.pY - mouseLocation.current.y) < sensitivity) {
            updateHovering(true);
        } else {
            mouseLocation.current.pX = mouseLocation.current.x;
            mouseLocation.current.pY = mouseLocation.current.y;
            timer.current = window.setTimeout(() => handleTick(), interval);
        }
      }, [updateHovering]);

    const handleMouseMove = useCallback((e: MouseEvent) => {
        mouseLocation.current.x = e.clientX;
        mouseLocation.current.y = e.clientY;
    }, []);

    const handleMouseOver = useCallback((e: MouseEvent) => {
        resetTimer();

        if(ref.current) {
            ref.current.addEventListener('mousemove', handleMouseMove, false);
        }

        if (!isHovering) {
            mouseLocation.current.pX = e.clientX;
            mouseLocation.current.pY = e.clientY;
            timer.current = window.setTimeout(() => handleTick(), interval);
        }
    }, [ref, isHovering]);

    const handleMouseOut = useCallback((e: MouseEvent) => {
        resetTimer();

        if(ref.current) {
            ref.current.removeEventListener('mousemove', handleMouseMove, false);
        }

        if(isHovering) {
            timer.current = window.setTimeout(() => handleDelayedMouseOut(), timeout);
        }
    }, [ref, isHovering]);

    useEffect(() => {
        if(ref.current) {
            ref.current.addEventListener('mouseover', handleMouseOver, false);
            ref.current.addEventListener('mouseout', handleMouseOut, false);
        }

        return () => {
            resetTimer();
            if(ref.current) {
                ref.current.removeEventListener('mouseover', handleMouseOver, false);
                ref.current.removeEventListener('mouseout', handleMouseOut, false);
            }
        }
    }, [ref, handleMouseOut, handleMouseOver]);

    return [isHovering];
}