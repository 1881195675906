import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faGithub, faGoogle, faInstagram, faLinkedin, faMedium, faTiktok, faTwitter, faXing, faYoutube, IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { faQuestion } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Link from "../link/Link";

export type SocialMediaIcon = 'facebook' | 'twitter' | 'instagram' | 'linkedin' | 'medium' | 'youtube' | 'tiktok' | 'github' | 'google' | 'xing';

export type SocialMediaLinkProps = {
    icon: SocialMediaIcon,
    link: string
};

const mapIcon = (icon: SocialMediaIcon): IconDefinition => {
    switch (icon) {
        case 'facebook':
            return faFacebook;
        case 'twitter':
            return faTwitter;
        case 'instagram':
            return faInstagram;
        case 'linkedin':
            return faLinkedin;
        case 'youtube':
            return faYoutube;
        case 'github':
            return faGithub;
        case 'google':
            return faGoogle;
        case 'medium':
            return faMedium;
        case 'tiktok':
            return faTiktok;
        case 'xing':
            return faXing;
    
        default:
            return faQuestion;
    }
}

export default function SocialMediaLink({
    icon,
    link
}: SocialMediaLinkProps) {
    return (
        <Link to={link} title={icon} className="w-9 h-9 flex items-center justify-center bg-slate-300 text-slate-600 hover:bg-slate-400 dark:bg-slate-800 dark:hover:bg-slate-600 dark:text-white mr-2 mb-2">
            <FontAwesomeIcon icon={mapIcon(icon) as IconProp} />
        </Link>
    );
}