import React from 'react';
import { animated, SpringRef, useSpring, useTrail } from 'react-spring';
import makePath from '../../../helpers/makePath';
import useCategoryTree, {
  StrapiCategory,
} from '../../../hooks/useCategoryTree';
import { useLocale } from '../../../hooks/useLocale';
import { usePrevious } from '../../../hooks/usePrevious';
import StrapiImage from '../../images/StrapiImage';
import Link from '../../link/Link';
import MainMenuDropdownItem from './MainMenuDropdownItem';

export type MainMenuCategoryDropdownProps = {
  categoryId: number;
  active?: boolean;
  submenuVisible?: boolean;
  animationRef?: SpringRef<any>;
};

export function MainMenuCategoryDropdown({
  categoryId,
  active = false,
  submenuVisible = false,
  animationRef,
}: MainMenuCategoryDropdownProps) {
  const locale = useLocale();
  const categories = useCategoryTree(locale, categoryId);
  const rootCategory = categories.find((cat) => cat.strapi_id === categoryId);

  let rootItems: any[] = [
    ...rootCategory.children.map((item) => ({ ...item, type: 'category' })),
    ...rootCategory.strapiPages.map((item) => ({ ...item, type: 'page' })),
  ].sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0));

  rootItems = rootItems.map((item) => {
    let children = [];

    if (item.type === 'category') {
      children = [
        ...((item as any).children ?? []).map((item) => ({
          ...item,
          type: 'category',
        })),
        ...((item as any).strapiPages ?? []).map((item) => ({
          ...item,
          type: 'page',
        })),
      ].sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0));
    }

    item.subitems = children;

    return item;
  });

  const totalItems: number = rootItems
    .flatMap((item) => 1 + item.subitems.length)
    .reduce((a, b) => a + b, 0);

  const trail = useTrail(totalItems, {
    from: { opacity: 0 },
    to: { opacity: active ? 1 : 0 },
    config: { duration: 1000 / totalItems },
    reset: true,
  });

  const hasChildren =
    rootCategory.children.length + rootCategory.pages.length > 0;

  const containerSyles = useSpring({
    from: { display: 'none', opacity: 0 },
    to: active
      ? { display: 'grid', opacity: 1 }
      : submenuVisible
      ? { display: 'none', opacity: 0 }
      : [{ opacity: 0 }, { display: 'none' }],
    immediate: () => (submenuVisible ? true : false),
    reset: true,
    ref: animationRef,
  });

  if (!hasChildren) return null;

  let i = -1;

  return (
    <animated.div
      style={containerSyles}
      className={`grid grid-cols-3 gap-6 py-10`}
    >
      {rootItems.map((item) => {
        i++;
        return (
          <div className="" key={item.strapi_id}>
            <animated.div style={trail[i]}>
              <Link
                to={makePath(item.locale, item.slug)}
                className="block mb-5 uppercase text-slate-400 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-200 tracking-wider font-medium"
              >
                {item.title}
              </Link>
            </animated.div>
            {item.subitems.map((child) => {
              i++;
              return (
                <animated.div style={trail[i]} key={child.strapi_id}>
                  <MainMenuDropdownItem
                    to={makePath(child.locale, child.slug)}
                    icon={
                      <StrapiImage
                        image={child.icon}
                        className="brightness-0 dark:invert"
                      />
                    }
                    title={child.title}
                    subtitle={child.excerpt}
                  />
                </animated.div>
              );
            })}
          </div>
        );
      })}
    </animated.div>
  );
}

export default MainMenuCategoryDropdown;
