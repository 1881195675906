import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';
import React, {
  createContext,
  MouseEvent,
  PropsWithoutRef,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { forwardRef } from 'react';
import { navigate } from 'gatsby';

const isExternalLink = (path: string) => !/^\/(?!\/)/.test(path);

export type LinkProps<TState> = {
  prefetchBehaviour?: PrefetchBehaviour;
} & PropsWithoutRef<GatsbyLinkProps<TState>>;

export type LinkRef = HTMLAnchorElement & GatsbyLink<any>;

export type PrefetchBehaviour = 'default' | 'hover' | 'none';

export const PrefetchContext = createContext<PrefetchBehaviour>('default');

export const Link = forwardRef<LinkRef, LinkProps<any>>(
  ({ to, children, prefetchBehaviour, ...props }, ref) => {
    let prefetchBehaviourCtx = useContext(PrefetchContext);

    if (prefetchBehaviour) {
      prefetchBehaviourCtx = prefetchBehaviour;
    }

    const targetUrl = useMemo(() => {
      if (!isExternalLink(to) && !to.endsWith('/')) {
        return `${to}/`;
      }
      return to;
    }, [to]);

    const prefetch = useCallback(() => {
      (window as any).___loader.hovering(targetUrl);
    }, [targetUrl]);

    const navigateToTarget = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        navigate(targetUrl);
      },
      [navigate, targetUrl]
    );

    if (props.target === '_blank') {
      return (
        <a
          ref={ref}
          {...props}
          href={targetUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      );
    }

    if (isExternalLink(to)) {
      return (
        <a
          ref={ref}
          {...props}
          href={to}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      );
    }

    if (prefetchBehaviourCtx === 'default') {
      return (
        <GatsbyLink to={targetUrl} ref={ref} {...props}>
          {children}
        </GatsbyLink>
      );
    }

    if (prefetchBehaviourCtx === 'none') {
      return (
        <a href={targetUrl} onClick={navigateToTarget} ref={ref} {...props}>
          {children}
        </a>
      );
    }

    if (prefetchBehaviourCtx === 'hover') {
      return (
        <a
          href={targetUrl}
          onClick={navigateToTarget}
          onMouseEnter={(e) => {
            props.onMouseEnter && props.onMouseEnter(e);
            prefetch();
          }}
          ref={ref}
          {...props}
        >
          {children}
        </a>
      );
    }
  }
);

export default Link;
