import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useLocale } from '../../hooks/useLocale';
import useMainMenu from '../../hooks/useMainMenu';
import MainMenuCategoryItem from './items/MainMenuCategoryItem';
import { useLocation } from '@reach/router';
import Overlay from '../drawer/Overlay';
import MainMenuItem from './MainMenuItem';
import MainMenuDropdown from './MainMenuDropdown';
import MainMenuCategoryDropdown from './dropdowns/MainMenuCategoryDropdown';
import useMeasure from '../../hooks/useMeasure';
import {
  useChain,
  useSpringRef,
  useSprings,
  useTransition,
  animated,
} from 'react-spring';
import { PrefetchContext } from '../link/Link';

export type MainMenuProps = {};

export default function MainMenu({}: MainMenuProps) {
  const locale = useLocale();
  const mainMenu = useMainMenu(locale);
  const location = useLocation();

  const [activeSubmenu, setActiveSubmenu] = useState(null);

  useEffect(() => {
    setActiveSubmenu(null);
  }, [location]);

  return (
    <PrefetchContext.Provider value="hover">
      <nav
        className={`navigation hidden md:flex relative justify-center items-center`}
      >
        {mainMenu.map((item, idx) => {
          if (item.strapi_component === 'menu.category-tree') {
            return (
              <MainMenuCategoryItem
                key={`ct${item.category.strapi_id}`}
                categoryId={item.category.strapi_id}
                active={
                  activeSubmenu === `category_tree_${item.category.strapi_id}`
                }
                onActivate={(id) =>
                  setActiveSubmenu(id ? `category_tree_${id}` : null)
                }
              />
            );
          } else if (item.strapi_component === 'menu.external-link') {
            return (
              <MainMenuItem
                key={`ct${item.link}`}
                to={item.link}
                onMouseEnter={() => setActiveSubmenu(null)}
              >
                {item.title}
              </MainMenuItem>
            );
          }

          // Unknown component
          return null;
        })}
      </nav>
      <MainMenuDropdown active={activeSubmenu !== null}>
        {mainMenu.map((item, idx) => {
          if (item.strapi_component === 'menu.category-tree') {
            return (
              <MainMenuCategoryDropdown
                key={`ct${item.category.strapi_id}`}
                active={
                  activeSubmenu === `category_tree_${item.category.strapi_id}`
                }
                submenuVisible={activeSubmenu !== null}
                categoryId={item.category.strapi_id}
              />
            );
          }

          // Unknown component
          return null;
        })}
      </MainMenuDropdown>
      <Overlay
        onClick={() => setActiveSubmenu(null)}
        onMouseEnter={() => setActiveSubmenu(null)}
        visible={activeSubmenu !== null}
        keepHeader
      />
    </PrefetchContext.Provider>
  );
}
