import { RefCallback, useCallback, useEffect, useState } from 'react';

type Measurements = {
  clientOffset: {
    x: number;
    y: number;
  };
  size: {
    width: number;
    height: number;
  };
};

type MeasureOptions = {
  withPadding?: boolean;
  withMargin?: boolean;
  withBorder?: boolean;
  relativeToDocument?: boolean;
};

const defaultOptions = {
  withPadding: true,
  withBorder: true,
  withMargin: false,
  relativeToDocument: false,
};

export default function useMeasure<T extends HTMLElement>({
  withPadding = true,
  withBorder = true,
  withMargin = false,
  relativeToDocument = false,
}: MeasureOptions = defaultOptions): [RefCallback<T>, Measurements] {
  const [ref, setRef] = useState<T>(null);

  const [measurements, setMeasurements] = useState<Measurements>({
    clientOffset: { x: 0, y: 0 },
    size: { width: 0, height: 0 },
  });

  const measure = useCallback(() => {
    if (!ref) return;
    const boundingRect = ref.getBoundingClientRect();
    const computedStyles = window.getComputedStyle(ref);

    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    const marginLeft = parseFloat(computedStyles.marginLeft);
    const marginTop = parseFloat(computedStyles.marginTop);
    const paddingLeft = parseFloat(computedStyles.paddingLeft);
    const paddingTop = parseFloat(computedStyles.paddingTop);
    const borderLeft = parseFloat(computedStyles.borderLeftWidth);
    const borderTop = parseFloat(computedStyles.borderTopWidth);
    const paddingX = paddingLeft + parseFloat(computedStyles.paddingRight);
    const paddingY = paddingTop + parseFloat(computedStyles.paddingBottom);
    const marginX = marginLeft + parseFloat(computedStyles.marginRight);
    const marginY = marginTop + parseFloat(computedStyles.marginBottom);
    const borderX = borderLeft + parseFloat(computedStyles.borderRightWidth);
    const borderY = borderTop + parseFloat(computedStyles.borderBottomWidth);

    const width =
      boundingRect.width -
      (!withPadding ? paddingX : 0) -
      (!withBorder ? borderX : 0) +
      (withMargin ? marginX : 0);
    const height =
      boundingRect.height -
      (!withPadding ? paddingY : 0) -
      (!withBorder ? borderY : 0) +
      (withMargin ? marginY : 0);

    const x =
      boundingRect.x +
      (relativeToDocument ? scrollX : 0) -
      (withMargin ? marginLeft : 0) +
      (!withPadding ? paddingLeft : 0) +
      (!withBorder ? borderLeft : 0);
    const y =
      boundingRect.x +
      (relativeToDocument ? scrollY : 0) -
      (withMargin ? marginTop : 0) +
      (!withPadding ? paddingTop : 0) +
      (!withBorder ? borderTop : 0);

    setMeasurements({
      clientOffset: { x, y },
      size: { width, height },
    });
  }, [ref, withBorder, withMargin, withPadding, relativeToDocument]);

  const updateRef = useCallback((node) => {
    setRef(node);
  }, []);

  useEffect(() => {
    if (!ref) return;
    const observer = new ResizeObserver((entries) => {
      measure();
    });
    observer.observe(ref);

    if (relativeToDocument) {
      window.addEventListener('scroll', measure);
    }
    measure();

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', measure);
    };
  }, [measure, relativeToDocument, ref]);

  return [updateRef, measurements];
}
