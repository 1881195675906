import { useEffect, useState } from "react";

export default function useSSR() {
    const [isBrowser, setIsBrowser] = useState(false);

    useEffect(() => {
        setIsBrowser(true);
    }, []);

    return isBrowser;
}