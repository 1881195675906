export { slugify } from 'transliteration';

export default function makePath(locale: string, slug: string) {
  return `/${locale.toLowerCase()}/${slug.replace(/^\//, '')}`;
}

export const makeAbsolute = (locale: string, slug: string) => {
  return `${getBaseUrl()}${makePath(locale, slug)}`;
};

export const getBaseUrl = () => {
  return (
    process.env.GATSBY_SITE_URL ?? 'https://signature-products.com'
  ).replace(/\/$/, '');
};
