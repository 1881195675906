import React, { ReactNode } from 'react';
import Link from '../../link/Link';

export type MainMenuDropdownItemProps = {
  icon: ReactNode;
  title: string;
  subtitle?: string;
  to?: string;
};

export function MainMenuDropdownItem({
  icon,
  title,
  subtitle,
  to,
}: MainMenuDropdownItemProps) {
  return (
    <Link to={to} className="cursor-pointer flex items-center mb-5 group">
      <div className="w-12 h-12 p-2 mr-6 shrink-0 bg-slate-300 group-hover:bg-slate-400 dark:bg-slate-800 dark:group-hover:bg-slate-700 rounded-lg transition-colors">
        {icon}
      </div>
      <div className="">
        <div className="text-slate-700 group-hover:text-slate-900 dark:text-white dark:group-hover:text-white font-medium tracking-wide transition-colors">
          {title}
        </div>
        <div className="text-slate-400 group-hover:text-slate-500 dark:text-slate-500 text-sm dark:group-hover:text-slate-400 transition-colors">
          {subtitle}
        </div>
      </div>
    </Link>
  );
}

export default MainMenuDropdownItem;
