import React, { ReactNode } from 'react';

export type UnorderedListProps = {
  children: ReactNode;
};

export function UnorderedList({ children }: UnorderedListProps) {
  return <ul className="list-disc pl-6">{children}</ul>;
}

export default UnorderedList;
