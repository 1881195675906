import { faCog, faFingerprint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import useOutsideClick from '../../hooks/useOutsideClick';
import Card from '../cards/Card';
import Translated from '../translation/Translated';
import GsprGroup from './Group';
import Button from '../buttons/Button';
import useSSR from '../../hooks/useSSR';

export type CookieNoticeProps = {};

const CookieNotice = ({}: CookieNoticeProps) => {
  const isBrowser = useSSR();
  const [isGDPRShown, setGDPRShown] = useLocalStorage('gdpr', 'false');
  const [isMinimized, setMinimized] = useState(false);
  const [showBack, setShowBack] = useState(false);
  const [isGAactive, setGA] = useLocalStorage('analytics-consent', 'false');

  const denyAll = () => {
    setGA('false');
    setGDPRShown('true');
    setMinimized(true);
    if ((window as any).updateConsent) {
      (window as any).updateConsent();
    }
  };
  const acceptAll = () => {
    setGA('true');
    setGDPRShown('true');
    setMinimized(true);
    if ((window as any).updateConsent) {
      (window as any).updateConsent();
    }
  };

  const cardRef = useOutsideClick(() => {
    if (!isMinimized) {
      setMinimized(true);
    }
  });

  useEffect(() => {
    if (isGDPRShown === 'true') {
      setMinimized(true);
      setShowBack(true);
    }
  }, [isGDPRShown]);

  if (!isBrowser) {
    return null;
  }

  if (isMinimized) {
    return (
      <div
        onClick={() => setMinimized(false)}
        className="fixed text-2xl bottom-4 left-4 border border-gray-500 dark:border-gray-400 bg-white text-black dark:bg-black dark:text-white flex items-center justify-center shadow-xl rounded-full w-12 h-12 cursor-pointer opacity-40 hover:opacity-100"
      >
        <FontAwesomeIcon icon={faFingerprint} />
      </div>
    );
  }

  const front = (
    <div>
      <h6 className="px-2 py-2 font-bold text-lg">
        <Translated ns="gdpr" k="title">
          We value your privacy
        </Translated>
      </h6>
      <div className="text-xs max-h-24 md:max-h-full overflow-y-auto px-2 py-2">
        <Translated ns="gdpr" k="description">
          We and our partners use technologies such as cookies or targeting and
          process personal data such as IP address or browser information. This
          helps us to improve your internet experience. We also use this to
          measure results or customise the content of our website. Because we
          value your privacy, we hereby ask for your permission to use the
          following technologies. You can change / withdraw your consent at any
          time later by clicking on the settings in the bottom left corner of
          the page.
        </Translated>
      </div>
      <div className="mt-4 text-center md:text-right">
        <div className="space-x-2 md:inline-block md:mr-2">
          <Button
            type="outline"
            title="Settings"
            className="my-1"
            onClick={() => {
              setShowBack(true);
            }}
          >
            <FontAwesomeIcon icon={faCog} />
          </Button>
          <Button type="outline" className="my-1" onClick={denyAll}>
            <Translated ns="gdpr" k="declineAll">
              Decline
            </Translated>
          </Button>
          <Button className="my-1" onClick={acceptAll}>
            <Translated ns="gdpr" k="acceptAll">
              Accept
            </Translated>
          </Button>
        </div>
      </div>
    </div>
  );
  const back = (
    <div>
      <div className="overflow-y-auto" style={{ maxHeight: '60vh' }}>
        <h3 className="px-2 py-2 font-bold text-lg">
          <Translated ns="gdpr" k="settingsTitle">
            Privacy Settings
          </Translated>
        </h3>
        <p className="text-xs mb-4 text-gray-400 px-2 py-2">
          <Translated ns="gdpr" k="settingsDescription">
            This tool allows you to select and disable various tags / trackers /
            analytics tools used on this website.
          </Translated>
        </p>
        <div className="md:max-h-60 overflow-y-auto">
          <GsprGroup
            title={
              <Translated ns="gdpr" k="googleAnalyticsTitle">
                Google Analytics
              </Translated>
            }
            description={
              <Translated ns="gdpr" k="googleAnalyticsDescription">
                These tags allow us to analyse the use of the website to measure
                and improve its performance.
              </Translated>
            }
            active={isGAactive === 'true'}
            onChange={(active) => {
              setGA(active ? 'true' : 'false');
              if ((window as any).updateConsent) {
                (window as any).updateConsent();
              }
            }}
          />
          <GsprGroup
            title={
              <Translated ns="gdpr" k="essentialsTitle">
                Essentials
              </Translated>
            }
            description={
              <Translated ns="gdpr" k="essentialsDescription">
                These tags are required for the basic functions of the website.
              </Translated>
            }
            disabled
          />
        </div>
      </div>
      <div className="mt-4 text-center md:text-right">
        <div className="space-x-2 md:inline-block md:mr-2">
          <Button
            type="outline"
            onClick={() => {
              setGDPRShown('true');
              setMinimized(true);
            }}
            className="my-1"
          >
            <Translated ns="gdpr" k="close">
              Close
            </Translated>
          </Button>
          <Button type="outline" className="my-1" onClick={denyAll}>
            <Translated ns="gdpr" k="declineAll">
              Decline
            </Translated>
          </Button>
          <Button className="my-1" onClick={acceptAll}>
            <Translated ns="gdpr" k="acceptAll">
              Accept
            </Translated>
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="fixed bottom-2 left-2 right-2 z-40 md:right-auto"
      style={{ maxWidth: 600 }}
      ref={cardRef}
    >
      <Card
        flat
        className="md:px-4 md:py-4 px-2 py-2 bg-white dark:bg-black border border-gray-300 dark:border-gray-700 shadow-xl rounded-xl"
      >
        {showBack ? back : front}
      </Card>
    </div>
  );
};

export default CookieNotice;
