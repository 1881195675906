import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import makePath from '../../../helpers/makePath';
import useCategoryTree, {
  StrapiCategory,
} from '../../../hooks/useCategoryTree';
import { useLocale } from '../../../hooks/useLocale';
import CollapseItem from '../../collapse/CollapseItem';
import MobileMenuItem from './MobileMenuItem';

export type MobileMenuRootItemProps = {
  categoryId: number;
  onSelectSubCategory: (selected: StrapiCategory) => void;
};

export default function MobileMenuRootItem({
  categoryId,
  onSelectSubCategory,
}: MobileMenuRootItemProps) {
  const locale = useLocale();
  const categories = useCategoryTree(locale, categoryId);

  const rootCategory = categories.find((cat) => cat.strapi_id === categoryId);
  const rootItems = [
    ...rootCategory.children.map((item) => ({ ...item, type: 'category' })),
    ...rootCategory.strapiPages.map((item) => ({ ...item, type: 'page' })),
  ].sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0));

  return (
    <div className="">
      <MobileMenuItem
        isHeader
        to={makePath(rootCategory.locale, rootCategory.slug)}
      >
        {rootCategory.title}
      </MobileMenuItem>
      {rootItems.map((item) => {
        const hasChildren =
          item.type === 'category' &&
          (item as any).children.length + (item as any).strapiPages.length > 0;
        return (
          <MobileMenuItem
            onClick={() => {
              item.type === 'category' && onSelectSubCategory(item as any);
            }}
            key={item.strapi_id}
            hasChildren={hasChildren}
            to={makePath(item.locale, item.slug)}
          >
            {item.title}
          </MobileMenuItem>
        );
      })}
    </div>
  );
}
