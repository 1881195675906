import React from "react";
import LocalImage, { LocalImageProps } from "../images/LocalImage";

export type CircleFlagProps = {
    countryCode: string;
} & Omit<LocalImageProps, 'src'>;

export default function CircleFlag({countryCode, ...props}: CircleFlagProps) {
    if(!countryCode) return null;

    const parts = countryCode.split('-');
    const flagName = parts.length == 2 ? parts[1].toLowerCase() : parts[0].toLowerCase();

    return (
        <LocalImage src={`flags/${flagName}`} {...props}/>
    );
}