import React, { ComponentType, ReactComponentElement, ReactNode } from "react";
import { useDarkness } from "../../hooks/useDarkness";

export type CardProps = {
    children: ReactNode;
    flat?: boolean;
    className?: string;
};

export default function Card({
    children,
    flat = false,
    className = ''
}: CardProps) {
    const backgroundStyles = 'border-slate-300 bg-slate-100 dark:border-slate-700 dark:bg-slate-800';
    const textStyles = 'text-slate-900 dark:text-white';

    return <div className={`${textStyles} w-full ${flat ? '' : `rounded-xl border-2 transition-all ${backgroundStyles}`} ${className}`}>
        {children}
    </div>
}