import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { useMemo } from 'react';
import makePath from '../helpers/makePath';
import { StrapiApiImage } from '../components/images/StrapiImage';

export type StrapiCategory = {
  title: string;
  excerpt: string;
  locale: string;
  slug: string;
  strapi_id: number;
  strapi_parent: {
    id: number;
  };
  strapi_children: {
    id: number;
  }[];
  pages: {
    strapi_id: number;
  }[];
  children?: StrapiCategory[];
  strapiPages?: StrapiPage[];
  active?: boolean;
  priority?: number;
  icon?: StrapiApiImage;
};

export type StrapiPage = {
  title: string;
  excerpt: string;
  locale: string;
  slug: string;
  strapi_id: number;
  active?: boolean;
  priority?: number;
  icon?: StrapiApiImage;
};

function isActive(
  pageOrCategory: StrapiCategory | StrapiPage,
  location: string
) {
  const path = makePath(pageOrCategory.locale, pageOrCategory.slug);
  return location.replace(/\/$/, '') === path;
}

function decorateCategory(
  category: StrapiCategory,
  allCategories: StrapiCategory[],
  allPages: StrapiPage[],
  location: string
) {
  if (!category) return null;
  category.children = category.strapi_children.map(({ id }) => {
    const childCategory = decorateCategory(
      allCategories.find((cat) => cat.strapi_id === id),
      allCategories,
      allPages,
      location
    );
    return childCategory;
  });
  let hasActivePage = false;
  category.strapiPages = category.pages.map(({ strapi_id }) => {
    const page = allPages.find((page) => page.strapi_id == strapi_id);
    page.active = isActive(page, location);
    hasActivePage = hasActivePage || page.active;
    return page;
  });
  category.active = isActive(category, location) || hasActivePage;
  return category;
}

function propagateActiveState(category: StrapiCategory) {
  if (!category?.children) return category;

  category.children.map((child) => {
    child = propagateActiveState(child);
    if (child?.active) {
      category.active = true;
    }
  });

  return category;
}

export default function useCategoryTree(locale: string, rootId: number = null) {
  const { allStrapiCategory, allStrapiPage } = useStaticQuery(graphql`
    query {
      allStrapiCategory {
        nodes {
          title
          excerpt
          locale
          slug
          strapi_id
          priority
          icon {
            ...StrapiImageConstrained
          }
          strapi_parent {
            id
          }
          strapi_children {
            id
          }
          pages {
            strapi_id
          }
        }
      }
      allStrapiPage {
        nodes {
          strapi_id
          slug
          title
          excerpt
          locale
          priority
          icon {
            ...StrapiImageConstrained
          }
        }
      }
    }
  `);

  const location = useLocation();

  return useMemo(() => {
    const categories: StrapiCategory[] = allStrapiCategory.nodes.filter(
      (cat: StrapiCategory) => cat.locale.toLowerCase() === locale.toLowerCase()
    );
    const decoratedCategories = categories
      .filter((cat) => cat.strapi_id == rootId)
      .map((cat) =>
        decorateCategory(
          cat,
          categories,
          allStrapiPage.nodes,
          location.pathname
        )
      );
    return decoratedCategories.map((cat) => propagateActiveState(cat));
  }, [allStrapiCategory, allStrapiPage, locale, rootId, location]);
}
