import { MutableRefObject, RefCallback, useCallback } from "react";

export default function useMultiRef<T>(refs: (RefCallback<T>|MutableRefObject<T>)[]) {
    const setMainRef = useCallback((val: T) => {
        refs.forEach((ref) => {
            if(typeof ref === 'function') {
                ref(val);
            } else if(typeof ref === 'object' && ref !== null) {
                (ref as MutableRefObject<T>).current = val;
            }
        });
    }, [refs]);

    return setMainRef;
}