import React from "react";
import Popover, { PopoverProps } from "../popover/Popover";
import PopoverBody from "../popover/PopoverBody";
import CircleFlag from "./CircleFlag";
import LanguageSwitcherItem, { LanguageSwitcherItemProps } from './LanguageSwitcherItem';

export type LanguageSwitcherProps = {
  activeLocale: string,
  locales: LanguageSwitcherItemProps[];
} & Omit<PopoverProps, 'keepDom'|'content'|'children'>;

export default function LanguageSwitcher({
  activeLocale,
  className = '',
  locales,
  ...props
}: LanguageSwitcherProps) {
    const localePopover = <PopoverBody className="bg-white dark:bg-slate-900 dark:shadow-black">
      { 
        locales
          ?.sort((a,b) => a.locale.localeCompare(b.locale))
          .map((locale) => <LanguageSwitcherItem key={locale.locale} {...locale}/>)
      }
    </PopoverBody>

    return (
        <Popover keepDom className={`flex ${className}`} content={localePopover} {...props}>
          <div className="w-14 flex justify-center items-center cursor-pointer hover:bg-slate-800">
            <CircleFlag countryCode={activeLocale} className="w-7 h-7" />
          </div>
        </Popover>
      );
}