import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import makePath from '../../../helpers/makePath';
import { StrapiCategory } from '../../../hooks/useCategoryTree';
import useDelayed from '../../../hooks/useDelayed';
import { useLocale } from '../../../hooks/useLocale';
import useMainMenu from '../../../hooks/useMainMenu';
import CollapseItem from '../../collapse/CollapseItem';
import Drawer from '../../drawer/Drawer';
import { PrefetchContext } from '../../link/Link';
import MobileMenuItem from './MobileMenuItem';
import MobileMenuRootItem from './MobileMenuRootItem';

export type MobileMenuProps = {
  open: boolean;
};

export const MobileMenu = forwardRef<HTMLDivElement, MobileMenuProps>(
  ({ open }, ref) => {
    const locale = useLocale();
    const mainMenu = useMainMenu(locale);

    const [selectedSub, setSelectedSub] = useState<StrapiCategory>(null);
    const [showSub, setShowSub] = useState<boolean>(false);
    const delayedOpen = useDelayed(open, 500);

    const timerRef = useRef<number>();

    const setSub = useCallback(
      (sub: StrapiCategory) => {
        if (selectedSub === sub) return;
        window.clearTimeout(timerRef.current);
        if (sub) {
          setSelectedSub(sub);
          setShowSub(true);
        } else {
          setShowSub(false);
          timerRef.current = window.setTimeout(() => {
            setSelectedSub(null);
          }, 500);
        }
      },
      [selectedSub]
    );

    useEffect(() => {
      if (!delayedOpen) {
        setShowSub(false);
        setSelectedSub(null);
      }
    }, [delayedOpen]);

    const subItems = selectedSub
      ? [
          ...selectedSub.children.map((item) => ({
            ...item,
            type: 'category',
          })),
          ...selectedSub.strapiPages.map((item) => ({ ...item, type: 'page' })),
        ].sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))
      : [];

    return (
      <Drawer
        ref={ref}
        open={open}
        keepHeader
        className="w-80 flex bg-white dark:bg-slate-800 text-slate-700 dark:text-white border-t border-slate-700 border-l"
      >
        <PrefetchContext.Provider value="hover">
          <div
            style={{
              width: '40rem',
              transform: `translate(${showSub ? -20 : 0}rem, 0)`,
            }}
            className="flex transition-transform duration-300"
          >
            <div className="w-80 overflow-y-auto">
              {mainMenu.map((item, idx) => {
                if (item.strapi_component === 'menu.category-tree') {
                  return (
                    <MobileMenuRootItem
                      onSelectSubCategory={(cat) => setSub(cat)}
                      key={item.category.strapi_id}
                      categoryId={item.category.strapi_id}
                    />
                  );
                } else if (item.strapi_component === 'menu.external-link') {
                  return (
                    <MobileMenuItem isHeader to={item.link} key={item.link}>
                      {item.title}
                    </MobileMenuItem>
                  );
                }

                // Unknown component
                return null;
              })}
            </div>
            <div className="w-80 overflow-y-auto">
              {selectedSub && (
                <>
                  <MobileMenuItem
                    isBack
                    isHeader
                    onClick={() => setSub(null)}
                    to={makePath(selectedSub.locale, selectedSub.slug)}
                  >
                    {selectedSub.title}
                  </MobileMenuItem>
                  {subItems.map((item) => (
                    <MobileMenuItem
                      to={makePath(item.locale, item.slug)}
                      key={item.strapi_id}
                    >
                      {item.title}
                    </MobileMenuItem>
                  ))}
                </>
              )}
            </div>
          </div>
        </PrefetchContext.Provider>
      </Drawer>
    );
  }
);
