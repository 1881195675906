import { MutableRefObject, useCallback, useEffect, useRef } from "react";

export type OutsideClickOptions<T extends HTMLElement> = {
  el?: MutableRefObject<T>,
  ignore?: MutableRefObject<any>[]
}

export default function useOutsideClick<T extends HTMLElement>(handler: () => void, {el, ignore}: OutsideClickOptions<T> = {}) {
  let ref = useRef<T>();

  if(el) ref = el;

  const handlerCallback = useCallback(
    (e: any) => {
      if (!ref.current || ref.current.contains(e.target)) {
        e.stopPropagation();
        return;
      }

      if(ignore) {
        for (const ignoreRef of ignore) {
          if(ignoreRef.current !== null && ignoreRef.current.contains(e.target)) {
            e.stopPropagation();
            return
          };
        }
      }

      handler();
    },
    [handler, ignore]
  );

  const callbackRef = useCallback((node: any) => {
    if (!node) return;
    ref.current = node;
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handlerCallback);

    return () => {
      document.removeEventListener("mousedown", handlerCallback);
    };
  }, [handlerCallback]);

  return callbackRef;
};
