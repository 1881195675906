import { graphql } from 'gatsby';
import React, { HTMLProps, ReactNode } from 'react';
import { useDarkness } from '../../hooks/useDarkness';
import Link, { LinkProps } from '../link/Link';

export type StrapiApiButton = {
  id?: string;
  title?: string;
  type?: 'primary' | 'secondary' | 'outline' | 'link' | 'custom';
  link?: string;
  small?: boolean;
};

export type ButtonProps = {
  children?: ReactNode;
} & StrapiApiButton &
  (LinkProps<any> | HTMLProps<HTMLButtonElement>);

export default function Button({
  children,
  type = 'primary',
  link,
  className = '',
  title,
  small = false,
  onClick,
  ...props
}: ButtonProps) {
  let btnClass = `${className} uppercase tracking-wide`;

  if (type !== 'link') {
    btnClass = `${
      small ? 'px-6 py-2 text-sm rounded-md' : 'px-6 py-2 rounded-lg'
    } font-bold transition-colors border ${btnClass}`;
  }

  if (type === 'custom') {
  } else if (type === 'primary') {
    btnClass = `${btnClass} border-black bg-black text-white hover:bg-slate-900 hover:border-slate-900 dark:border-white dark:bg-white dark:hover:bg-slate-200 dark:hover:border-slate-200 dark:text-slate-800`;
  } else if (type === 'secondary') {
    btnClass = `${btnClass} border-slate-200 bg-slate-200 text-slate-600 hover:bg-slate-300 hover:border-slate-300 dark:border-slate-700 dark:bg-slate-700 dark:text-slate-300 dark:hover:bg-slate-800 dark:hover:border-slate-800`;
  } else if (type === 'outline') {
    btnClass = `${btnClass} border-2 border-black text-black rounded-lg hover:bg-black hover:text-white dark:border-white dark:text-white dark:rounded-lg dark:hover:bg-white dark:hover:text-slate-800`;
  }

  if (onClick || !link) {
    //@ts-ignore
    return (
      <button
        type="button"
        onClick={onClick}
        className={btnClass}
        title={title}
        {...(props as HTMLProps<HTMLButtonElement>)}
      >
        {children ?? title}
      </button>
    );
  }

  return (
    <Link
      to={link}
      className={btnClass}
      title={title}
      {...(props as LinkProps<any>)}
    >
      {children ?? title}
    </Link>
  );
}

export const query = graphql`
  fragment Button on STRAPI__COMPONENT_COMPONENTS_BUTTON {
    id
    title
    link
    type
  }
`;
