import { Placement } from '@popperjs/core';
import React, {
  HTMLProps,
  ReactNode,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useEffect } from 'react';
import { usePopper } from 'react-popper';
import useHover from '../../hooks/useHover';
import useOutsideClick from '../../hooks/useOutsideClick';

export type PopoverProps = {
  children: ReactNode;
  content: ReactNode;
  keepDom?: boolean;
  interval?: number;
  timeout?: number;
  open?: boolean;
  placement?: Placement;
} & Omit<
  HTMLProps<HTMLDivElement>,
  'content' | 'onTouchStart' | 'onMouseEnter' | 'onMouseLeave'
>;

export default function Popover({
  children,
  content,
  keepDom = false,
  interval = 0,
  timeout = 0,
  open = null,
  placement,
  ...props
}: PopoverProps) {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(open ?? false);
  useHover({
    ref: { current: referenceElement },
    interval,
    timeout,
    onStartHover: () => open === null && setOpen(true),
    onEndHover: () => open === null && setOpen(false),
  });

  const modifiers: any = useMemo(
    () => [
      {
        name: 'sameWidth',
        enabled: true,
        fn: ({ state }) => {
          state.styles.popper.minWidth = `${state.rects.reference.width}px`;
        },
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
    ],
    []
  );

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers,
    }
  );

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useLayoutEffect(() => {
    update && update();
  }, [isOpen]);

  useOutsideClick(
    () => {
      if (open === null && isOpen) {
        setOpen(false);
      }
    },
    { el: { current: referenceElement } }
  );

  return (
    <>
      <div
        onTouchStart={() => open === null && setOpen(true)}
        ref={setReferenceElement}
        {...props}
      >
        {children}

        {(isOpen || keepDom) && (
          <div
            ref={setPopperElement}
            style={{
              ...styles.popper,
              display: isOpen || !keepDom ? 'block' : 'none',
              zIndex: 1000,
            }}
            {...attributes.popper}
          >
            {content}
          </div>
        )}
      </div>
    </>
  );
}
