import React, { ReactNode } from 'react';

export type GdprGroupProps = {
  title: ReactNode;
  description: ReactNode;
  active?: boolean;
  disabled?: boolean;
  onChange?: (active: boolean) => void;
};

const GdprGroup = ({
  title,
  description,
  active,
  disabled,
  onChange,
}: GdprGroupProps) => {
  return (
    <div
      className={`${
        disabled
          ? 'bg-gray-200 text-gray-500 dark:bg-gray-900 dark:border-gray-700'
          : active
          ? 'border-blue-500 bg-blue-500 hover:bg-blue-600 dark:border-blue-600 dark:bg-blue-900 dark:hover:bg-blue-700 text-white'
          : 'border-gray-200 hover:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-900'
      } border rounded-md p-4 mb-2 cursor-pointer`}
      onClick={() => onChange && onChange(!active)}
    >
      <div className="text-sm font-bold">{title}</div>
      <div className="text-xs opacity-70">{description}</div>
    </div>
  );
};

export default GdprGroup;
