import React from "react";
import { useContext, createContext, ReactNode } from "react";

const LocaleContext = createContext<string>(undefined);

export const useLocale = () => {
    return useContext(LocaleContext);
};

export const LocaleProvider = ({
    children,
    locale,
  }: {
    children: ReactNode;
    locale?: string;
  }) => {
    return (
      <LocaleContext.Provider value={locale?.toLowerCase()}>
        {children}
      </LocaleContext.Provider>
    );
  };
  