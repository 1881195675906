import { graphql } from 'gatsby';
import {
  GatsbyImage,
  GatsbyImageProps,
  getImage,
  ImageDataLike,
} from 'gatsby-plugin-image';
import React from 'react';

export type StrapiApiImage = {
  id: number;
  alternativeText: string;
  localFile: ImageDataLike & {
    publicURL: string;
    extension: string;
  };
  width: number;
  height: number;
};

export type StrapiImageProps = {
  image: StrapiApiImage;
  className: string;
  fitWidth?: boolean;
} & Omit<GatsbyImageProps, 'alt' | 'image' | 'className'>;

export default function StrapiImage({
  image,
  className,
  fitWidth = false,
  ...props
}: StrapiImageProps) {
  if (!image) return null;

  if (image.localFile.extension === 'svg') {
    return (
      <img
        alt={image.alternativeText}
        src={image.localFile.publicURL}
        className={`${fitWidth ? '' : 'w-full'} ${className}`}
        {...props}
      />
    );
  }

  return (
    <GatsbyImage
      alt={image.alternativeText}
      image={getImage(image.localFile)}
      className={className}
      {...props}
    />
  );
}

export const query = graphql`
  fragment StrapiImageConstrained on STRAPI__MEDIA {
    id
    alternativeText
    localFile {
      publicURL
      extension
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }

  fragment StrapiImageFullWidth on STRAPI__MEDIA {
    id
    alternativeText
    localFile {
      publicURL
      extension
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    width
    height
  }
`;
