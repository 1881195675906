import { useEffect, useRef, useState } from "react";

export default function useDelayed<T>(value: T, delay: number) {
    const [delayedValue, setDelayedValue] = useState<T>(value);
    const timer = useRef<number>();

    useEffect(() => {
        if(timer.current) window.clearTimeout(timer.current);
        timer.current = window.setTimeout(() => {
            setDelayedValue(value);
        }, delay);
    }, [value]);

    return delayedValue;
}