import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import Link from "../../link/Link";

export type MobileMenuItemProps = {
    children: ReactNode,
    isHeader?: boolean,
    isBack?: boolean,
    hasChildren?: boolean,
    onClick?: () => void,
    to?: string;
};

export default function MobileMenuItem({
    children,
    hasChildren = false,
    isHeader = false,
    isBack = false,
    to,
    onClick
}: MobileMenuItemProps) {

    const commonStyles = `flex h-12 border-b ${isHeader ? 'font-bold uppercase bg-slate-200 dark:bg-slate-900 border-slate-200 border-slate-300 dark:border-slate-700' : 'border-slate-200 dark:border-slate-700'}`;
    const hoverStyles = `${isHeader ? 'hover:bg-slate-300 dark:hover:bg-slate-700' : 'hover:bg-slate-100 dark:hover:bg-slate-600'}`;
    const commonArrowStyles = `flex-shrink-0 cursor-pointer ${hoverStyles} flex items-center justify-center w-12 border-r ${isHeader ? 'border-slate-300 dark:border-slate-800' : 'border-slate-200 dark:border-slate-700'}`

    if(isBack) {
        return <div className={`${commonStyles}`}>
            <div onClick={onClick} className={`${commonArrowStyles} border-r`}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <Link to={to} className={`px-4 flex flex-grow items-center ${hoverStyles}`}>{children}</Link>
        </div>;
    }

    if(hasChildren && onClick) {
        return <div className={`${commonStyles}`}>
            <Link to={to} className={`px-4 flex flex-grow items-center ${hoverStyles}`}>{children}</Link>
            <div onClick={onClick} className={`${commonArrowStyles} border-l`}>
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
        </div>;
    }

    return (
        <Link to={to} className={`${commonStyles} ${hoverStyles} px-4 items-center`}>{children}</Link>
    );
}