import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, GatsbyImageProps, getImage } from "gatsby-plugin-image";
import React, { useMemo } from "react";

export type LocalImageProps = {
    src: string;
    alt?: string;
} & Omit<GatsbyImageProps, 'image'|'alt'>;

export default function LocalImage({src, alt, ...props}: LocalImageProps) {
    const { allFile } = useStaticQuery(graphql`
        query {
            allFile(
                filter: {
                extension: { in: ["svg", "jpeg", "jpg", "png"] }
                sourceInstanceName: { eq: "images" }
                }
            ) {
                edges {
                    node {
                        relativePath
                        extension
                        name
                        publicURL
                        childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    `);

    const image = useMemo(() => {
        if (!src) return null;

        return allFile.edges.find((edge: any) => {
        const relativeSource = src.replace(/^\.?\//, "");
        const file = edge.node;

        return (
            relativeSource === file.relativePath ||
            relativeSource ===
            file.relativePath.replace(new RegExp(`\.${file.extension}$`), "")
        );
        })?.node;
    }, [src]);

    if(!image) {
        return null;
    }

    if (image.extension === "svg") {
        return <img src={image.publicURL} alt={alt ?? image.name} {...props}/>;
    }

    return (
        <GatsbyImage image={getImage(image)} alt={alt ?? image.name} {...props} />
    );
}