import { graphql, PageProps } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { useLocale } from '../../hooks/useLocale';
import useOutsideClick from '../../hooks/useOutsideClick';
import Drawer from '../drawer/Drawer';
import Logo from '../images/Logo';
import LanguageSwitcher from '../language-switcher/LanguageSwitcher';
import { LanguageSwitcherItemProps } from '../language-switcher/LanguageSwitcherItem';
import Link, { PrefetchContext } from '../link/Link';
import MainMenu from '../main-menu/MainMenu';
import { MobileMenu } from '../main-menu/mobile-menu/MobileMenu';
import MobileMenuTrigger from '../main-menu/mobile-menu/MobileMenuTrigger';
import { useLocation } from '@reach/router';
import SiteMeta, { SiteMetaProps } from '../meta/SiteMeta';
import { slugify } from '../../helpers/makePath';

export type HeaderProps = Omit<PageProps<any, any>, 'children'>;

export default function Header({ data }: HeaderProps) {
  const location = useLocation();
  const locale = useLocale();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const headerRef = useRef();

  const mobileMenuRef = useOutsideClick(
    () => {
      setMobileMenuOpen(false);
    },
    { ignore: [headerRef] }
  );

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  let availableLocales: LanguageSwitcherItemProps[] = [];
  let siteMeta: SiteMetaProps = {
    title: 'Signature Products GmbH',
    description: '',
  };
  let slug: string = '/';

  if (data?.strapiPage) {
    availableLocales =
      data.strapiPage?.localizations?.data?.map(
        (node: any) => node.attributes
      ) ?? [];
    siteMeta = data.strapiPage?.meta ?? {
      title: data.strapiPage.title,
      description: data.strapiPage.excerpt,
    };
    slug = data.strapiPage.slug;
  }
  if (data?.strapiCategory) {
    availableLocales =
      data.strapiCategory?.localizations?.data?.map(
        (node: any) => node.attributes
      ) ?? [];
    siteMeta = data.strapiCategory?.meta ?? {
      title: data.strapiCategory.title,
      description: data.strapiCategory.excerpt,
    };
    slug = data.strapiCategory.slug;
  }
  if (data?.allStrapiJobOffer) {
    const offer = data.allStrapiJobOffer.nodes[0];
    availableLocales =
      offer?.localizations?.data?.map((node: any) => ({
        locale: node.attributes.locale,
        slug: slugify(node.attributes.title),
      })) ?? [];
    siteMeta = offer?.siteMeta ?? {
      title: offer.title,
      description: offer.excerpt,
    };
    slug = slugify(offer.title);
  }

  siteMeta.availableLocales = availableLocales;
  siteMeta.locale = locale;
  siteMeta.slug = slug;

  return (
    <PrefetchContext.Provider value="hover">
      <header
        ref={headerRef}
        className="fixed z-40 top-0 inset-x-0 shadow-md border-b border-slate-900 transform-gpu"
      >
        <div className="bg-black text-white">
          <div className={`container m-auto flex justify-between h-14`}>
            <div className="sm:px-8 px-4 flex justify-center items-center">
              <Link to={`/${locale ?? ''}`}>
                <Logo colour="negative" className="h-9 w-32" loading="eager" />
              </Link>
            </div>
            {locale && (
              <>
                <MainMenu />
                <div className="flex justify-end">
                  <MobileMenuTrigger
                    open={mobileMenuOpen}
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    className="md:hidden"
                  />
                  <LanguageSwitcher
                    activeLocale={locale}
                    locales={availableLocales}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </header>
      <MobileMenu ref={mobileMenuRef} open={mobileMenuOpen} />
      <SiteMeta {...siteMeta} />
    </PrefetchContext.Provider>
  );
}
