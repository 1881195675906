import React, { HTMLProps, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import useSSR from '../../hooks/useSSR';

export type OverlayProps = {
  keepHeader?: boolean;
  visible?: boolean;
} & HTMLProps<HTMLDivElement>;

export default function Overlay({
  keepHeader = false,
  visible = true,
  ...rest
}: OverlayProps) {
  const isBrowser = useSSR();

  if (!isBrowser) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={`fixed ${
        keepHeader ? 'top-14 z-30' : 'top-0 z-40'
      } inset-x-0 bottom-0 ${
        visible
          ? 'bg-opacity-30 backdrop-blur-md'
          : 'bg-opacity-0 backdrop-blur-0 pointer-events-none'
      } bg-black transform-gpu transition-all`}
      {...rest}
    />,
    document.getElementById('overlay')
  ) as unknown as JSX.Element;
}
