import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import useFooter from '../../hooks/useFooter';
import { useLocale } from '../../hooks/useLocale';
import usePreferredColorSchema from '../../hooks/usePreferredColorSchema';
import Logo from '../images/Logo';
import StrapiImage from '../images/StrapiImage';
import Link, { PrefetchContext } from '../link/Link';
import Translated from '../translation/Translated';
import SocialMediaLink from './SocialMediaLink';

export type FooterProps = {};

export default function Footer({}: FooterProps) {
  const locale = useLocale();
  const footer = useFooter(locale);
  const colorSchema = usePreferredColorSchema();

  return (
    <PrefetchContext.Provider value="hover">
      <div className="border-t-2 border-b-2 border-slate-300 bg-slate-100  text-slate-600 dark:bg-slate-800 dark:text-slate-400 dark:border-slate-700">
        <div className="container mx-auto sm:px-8 px-4 sm:py-12 py-12">
          <div className="lg:flex block items-center">
            <div className="grow basis-0 lg:mb-0 mb-8">
              <div className="sm:text-4xl text-3xl mb-2 font-bold text-slate-700 dark:text-slate-200">
                <Translated k="contactUs" />
              </div>
              <div className="max-w-prose md:pr-4">{footer.contactCta}</div>
            </div>
            <div className="grow basis-0">
              <Link
                to={`tel:${footer.contactPhone}`}
                className="group flex items-center mb-6"
              >
                <div className=" flex justify-center items-center sm:text-2xl text-lg sm:rounded-xl  rounded-lg sm:w-12 sm:h-12 w-10 h-10 bg-green-500 shadow-lg text-white mr-4">
                  <FontAwesomeIcon fixedWidth icon={faPhone} />
                </div>
                <div className="grow sm:text-xl text-lg font-bold tracking-wide group-hover:text-black dark:group-hover:text-white dark:text-slate-300">
                  {footer.contactPhone}
                </div>
              </Link>
              <Link
                to={`mailto:${footer.contactEmail}`}
                className="group flex items-center"
              >
                <div className=" flex justify-center items-center sm:text-2xl text-lg sm:rounded-xl rounded-lg sm:w-12 sm:h-12 w-10 h-10 bg-blue-500 shadow-lg text-white mr-4">
                  <FontAwesomeIcon fixedWidth icon={faEnvelope} />
                </div>
                <div className="grow sm:text-xl text-lg font-bold tracking-wide group-hover:text-black dark:group-hover:text-white dark:text-slate-300">
                  {footer.contactEmail}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-slate-200 dark:bg-slate-900">
        <div className="container mx-auto sm:px-8 px-4 py-12 sm:py-16">
          <div className="sm:flex">
            <div className="w-full flex flex-wrap justify-center sm:block items-center mb-12 sm:mb-0 sm:w-1/3 xl:w-1/5 mr-8 flex-shrink-0">
              <div className="w-12 sm:mb-6 mr-4">
                <Logo
                  className="h-12 w-12"
                  format="icon"
                  colour={colorSchema === 'light' ? 'positive' : 'negative'}
                />
              </div>
              <div className="text-slate-700 dark:text-slate-300 uppercase text-sm tracking-widest max-w-[200px]">
                {footer?.slogan}
              </div>
              {footer.socialLinks && (
                <div className="mt-8 flex justify-center sm:justify-start flex-wrap w-full">
                  {footer.socialLinks.map(({ strapi_id, ...link }) => (
                    <SocialMediaLink key={strapi_id} {...link} />
                  ))}
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 xs:grid-cols-2 xl:grid-cols-4 gap-8 flex-grow">
              {footer?.items.map((item) => (
                <div key={item.strapi_id} className="">
                  <div className="font-bold uppercase tracking-wide text-slate-600 dark:text-slate-400 mb-4">
                    {item.title}
                  </div>
                  {item?.content && (
                    <div className="text-slate-700 dark:text-slate-300">
                      <MDXRenderer>
                        {item.content.data.childMdx.body}
                      </MDXRenderer>
                    </div>
                  )}
                  {item?.links?.map((link) => (
                    <Link
                      className="block mb-4 text-slate-700 dark:text-slate-300 dark:hover:text-white hover:text-black"
                      key={link.strapi_id}
                      to={link.to}
                    >
                      {link.title}
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-slate-200 dark:bg-slate-900 ">
        <div className="container mx-auto sm:px-8 px-4 pb-8 ">
          <div className="grid xl:gap-16 gap-8 xl:grid-cols-5 sm:grid-cols-3 grid-cols-2 border-t-2 border-slate-300 dark:border-slate-800 sm:pt-12 pt-8">
            {footer.partnerLogos.map((partner) => (
              <Link
                key={partner.link}
                to={partner.link}
                className="block group"
              >
                <div className="p-4 bg-white dark:bg-slate-800 rounded-lg shadow-md dark:shadow-black/30">
                  <StrapiImage
                    image={partner.logo}
                    objectFit="contain"
                    className="h-12 contrast-0 dark:brightness-200 group-hover:filter-none transition-all"
                  />
                </div>
                <div className="mt-4 text-xs text-center tracking-wider text-gray-700 font-medium group-hover:text-black dark:text-gray-400 dark:group-hover:text-white">
                  {partner.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-slate-300 dark:bg-black dark:border-t dark:border-slate-900 ">
        <div className="container mx-auto sm:px-8 px-4 py-2 ">
          <div className="text-gray-700 dark:text-gray-400 text-sm text-center sm:text-left">
            {footer?.copyright}
          </div>
        </div>
      </div>
    </PrefetchContext.Provider>
  );
}
